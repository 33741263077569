.n-wrapper {
    height: 10vh;
    display: flex;
    justify-content: space-between;
}

.n-left {
    flex: 1;
    align-items: center;
    display: flex;
    gap: 2rem;
}

.n-name {
    font-size: 1.3rem;
    font-weight: bolder;
}

.n-right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: 400; 
}

.n-list {
    flex: 10;
}

.n-list > ul {
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
    cursor: pointer;
}

.n-list > ul > li:hover {
    cursor: pointer;
    color: var(--orange);
}

.n-btn {
    width: 7rem;
    height: 2rem;
}

@media  screen and (max-width: 480px) {
    .n-list {
        display: none;
    }
    .n-btn {
        display: none;
    }
}
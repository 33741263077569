.card {
    gap: 1rem;
    width: 10rem;
    height: 13rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--orangeCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
    padding: 0 26px 2rem 26px;
}

.card span:nth-of-type(1) {
    font-weight: bold;
    font-size: 20px;
}

.card span:nth-of-type(2) {
    color: var(--gray);
    font-size: 18px;
}

.card img {
    margin-bottom: -1rem;
}

@media  screen and (max-width: 480px) {
    .card {
        width: 270px;
    }
}
.experience {
gap: 5rem;
justify-content: center;
align-items: center;
display: flex;
height: 30vh;
margin: 0 0 10rem 0;
}

.achievement {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle {
    height: 4rem;
    width: 4rem;
    background: white;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: black;
}

.circle::before {
    border-radius: 100%;
    content: '';
    background-image: linear-gradient(to bottom, #87e6fb 0%, #ffc05c 100%);
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    position: absolute;
    z-index: -1;
    box-shadow: var(--boxShadow);
}

.achievement span:nth-of-type(1) {
    font-weight: bold;
}

.achievement span:nth-of-type(2) {
    font-weight: bold;
    color: var(--yellow);
}

@media  screen and (max-width: 480px) {
    .experience {
        transform: scale(.7);
        margin: 7rem 0 0 0;
    
    }
}
.toggle {
    display: flex;
    justify-content: space-between;
    border: 3px solid var(--yellow);
    border-radius: 1rem;
    cursor: pointer;
    padding: 2px;
    position: relative;
}

.toggle>* {
    height: 1rem;
    width: 1rem;
    color: var(--yellow);
}

.t-btn {
    border-radius: 100%;
    background: var(--yellow);
    position: absolute;
}
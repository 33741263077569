.footer {
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: -35rem;
}

.f-content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 24rem;
    gap: 2rem;
    color: white;
}

.f-icons {
    display: flex;
    gap: 2rem;
}

.f-iconss {
    list-style-type: none;
}

@media  screen and (max-width: 480px) {
    .footer {
       transform: scale(0.8);
       border: 0;
        padding: 0;
        margin-top: -35rem;
        margin: -0.5rem -3.5rem;
    }
    
    .f-content {
        margin-top: 7rem;
        transform: scale(0.35);
    }

}
.c-left>* {
    font-size: 2rem;
    font-weight: bold;
}

.c-left span:nth-of-type(2) {
    color: var(--yellow);
}

.contact-form {
    padding: 0 3rem 0 3 rem;
    display: flex;
    height: 80vh;
    margin: 4rem 0 0 0;
}

.c-left {
    margin-left: 4rem;
}

.c-right {
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.c-right>form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.c-right .user {
    height: 2rem;
    width: 20rem;
    padding: 0.3rem;
    outline: none;
    border: 2px solid var(--yellow);
    border-radius: 8px;
    font-size: 16px;
}

textarea {
    height: 4rem!important;
}

@media  screen and (max-width: 480px) {
    .contact-form {
        flex-direction: column;
        height: 40rem;
    }

    .c-left {
        margin-left: 3rem;
        padding-bottom: 2rem;
        width: 330px;
    }

    .c-right .user {
        width: 330px;
    }
    
}
.works {
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 70vh;
    margin: 4rem 0 0 0;
}

.w-right {
    position: relative;
}

.w-mainCircle {
    left: 9rem;
    position: relative;
    height: 18rem;
    width: 18rem;
    border-radius: 100%;
    box-shadow: var(--smboxShadow);
    background: white;
}

.w-mainCircle>* {
position: absolute;
}

.w-mainCircle > :nth-child(1) {
    top: -3.5rem;
    left: 5.5rem;
}

.w-mainCircle > :nth-child(2) {
    top: 5.5rem;
    left: 16rem;
}

.w-mainCircle > :nth-child(3) {
    top: 5.5rem;
    left: -4.5rem;
}

.w-mainCircle > :nth-child(4) {
    top: 15.5rem;
    left: 5.5rem;
}

.w-mainCircle > :nth-child(5) {
    top: 5.5rem;
    left: 5.5rem;
}

.w-secCircle {
    height: 6rem;
    width: 6rem;
    position: absolute;
    border-radius: 100%;
    border: 5px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    background: "none";
    background: white;
}

.w-secCircle > img {
    transform: scale(0.5);
}

.w-backCircle {
    position: absolute;
    height: 13rem;
    width: 13rem;
    border-radius: 100%;
    z-index: -1;
}

.blueCircle {
    background: #1949B8;
    top: -4rem;
    left: 18rem;
    border: 5px solid #e8ebe5;
}

.yellowCircle {
    background: #F5C32C;
    top: 8rem;
    left: 18rem;
    border: 5px solid #e8ebe5;
}

@media  screen and (max-width: 480px) {
    .works {
        flex-direction: column;
        height: 55rem;
        padding: 0;
        gap: 5rem;
    }

    .w-right {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        transform: scale(0.68);
        left: -0.4rem!important;
    }

    .w-mainCircle {
        position: static;
    }

    .w-backCircle {
        left: 8rem!important;
    }
}
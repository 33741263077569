.services {
    padding: 3rem 3rem 0 3rem;
    display: flex;
    height: 90vh;
    margin-top: 8rem;
}

.myServices {
    display: flex;
    flex-direction: column;
    position: relative;
}

.myServices > :nth-child(1) {
    letter-spacing: 0.2rem;
    color: var(--black);
    font-size: 3rem;
    font-weight: bold;
}

.myServices > :nth-child(2) {
    letter-spacing: 0.2rem;
    font-size: 3rem;
    font-weight: bold;
    color:var(--yellow);
}

.s-btn {
    height: 2rem;
    width: 8rem;
    margin-top: 1rem;
}

.cards {
    position: relative;
}

.cards>* {
    position: absolute;
}


@media  screen and (max-width: 480px) {
    .services {
        margin-top: 0;
        flex-direction: column;
        gap: 5rem;
        height: 66rem;
        padding: 0;
    }

    .cards {
        display: flex;
        flex-direction: column;
        gap: 17rem;
    }

    .cards>* {
        position: static;
    }

}
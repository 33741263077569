.intro {
    display: flex;
    height: 77vh;
    margin-top: 6rem;
}

.i-left {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
}

.i-name {
    display: flex;
    flex-direction: column;
}

.i-name > :nth-child(1) {
    color: var(--black);   
    font-weight: bold;
    font-size: 3rem;
}

.i-name > :nth-child(2) {
    color: var(--yellow);
    font-weight: bold;
    font-size: 3rem;
}

.i-name > :nth-child(3) {
    color: var(--gray);
    font-weight: 100;
    font-size: 20px;
    margin: 10px 0 0 0;
}

.i-btn {
    width: 6rem;
    height: 2rem;
}

.i-icons {
    margin-top: 3rem;
    display: flex;
    padding: 0px;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
}

.i-iconss{
    margin-right: 2rem;
    text-decoration: none;
    color: inherit;
 }

 .i-icons>*:hover {
    cursor: pointer;
 }

  .i-intro {
    flex: 1;
 }

 .i-right {
    flex: 1;
    position: relative;
 }

 .i-right>* {
    position: absolute;
    z-index: 1;
 }

 .i-right>:nth-child(1) {
    transform: scale(1);
    top: -18%;
    right: -2%;
 }

 .i-right>:nth-child(2) {
    transform: scale(0.5);
    top: -35%;
    right: -10%;

 }

 .i-right>:nth-child(3) {    
    transform: scale(0.5);
    top: 20%;
    right: 28%;
 }

 .i-right>:nth-child(4) {
    transform: scale(0.5);
    top: 13%;
    right: -10%;
 }

 @media  screen and (max-width: 480px) {
    .intro {
        flex-direction: column;
        height: 64rem;
        gap: -5rem;
}

    .i-right>:nth-child(1) {
    transform: scale(0.45);
    left: -20rem;
    top: -5rem!important;
    }

 .i-right>:nth-child(2) {
    transform: scale(0.23);
    left: -21rem;
 }

 .i-right>:nth-child(3) {    
    transform: scale(0.18);
    left: -14rem;
    top: 5rem!important;
}

 .i-right>:nth-child(4) {
    transform: scale(0.17);
    left: -1rem;
    top: 3rem!important;
}
}
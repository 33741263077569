.t-wrapper {
    padding: 0 3rem 0 3rem;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.t-heading {
    align-self: flex-start;
}

.t-heading>* {
    font-size: 2rem;
    font-weight: bold;
}

.t-heading span:nth-of-type(2) {
    color: var(--yellow);
}

.testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
    height: 16rem;
    width: 35rem;
    background: rgba(225, 225, 225, 0.26);
    border: 7px solid var(--blueCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
}

.testimonial>img {
    height: 10rem;
    width: 10rem;
    border-radius: 100%;
    border: 5px solid var(--yellow);
}

.testimonial>span {
      color: var(--gray);
}

.t-wrapper .swiper {
    width: 100%;
    height: 70%;
}

.t-wrapper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.t-wrapper .swiper-pagination-bullet-active {
    background: var(--yellow);
}

@media  screen and (max-width: 480px) {
    .t-wrapper {
        padding: 0;
        width: 330px;
    }

    .testimonial>img {
        height: 70px;
        width: 70px;
    }
}